import React, { useState } from 'react';

const BookSelector = ({ exchangeMeta, onBookSelect }) => {
  const [selectedBooks, setSelectedBooks] = useState([]);

  const handleBookSelect = (coin) => {
    let newSelectedBooks = [];
    if (selectedBooks.includes(coin)) {
      // Remove book from selection
      newSelectedBooks = selectedBooks.filter(s => s !== coin);
    } else if (selectedBooks.length < 4) {
      // Add book to selection if less than 4 are already selected
      newSelectedBooks = [...selectedBooks, coin];
    } else {
      // Replace the first selected book if 4 are already selected
      newSelectedBooks = [...selectedBooks.slice(1), coin];
    }
    setSelectedBooks(newSelectedBooks);
    onBookSelect(coin);
  };

  return (
    <div style={{marginBottom: 15}}>
      {exchangeMeta.map(book => (
        <button 
          key={book.name} 
          onClick={() => handleBookSelect(book.name)}
          style={{ backgroundColor: selectedBooks.includes(book.name) ? 'lightgreen' : 'white' }}
        >
          {book.name}
        </button>
      ))}
    </div>
  );
};

export default BookSelector;
