import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import OracleUpdateTable from '../components/OracleUpdateTable';
import TxsTable from '../components/TxsTable';
import { getBlockDetails, getExchangeMeta, getExchangeSpotMeta } from '../services/hyperliquidClient';
import { formatExchangeMeta, formatExchangeSpotMeta } from '../services/formatter'
import AssetTable from '../components/AssetTable';


const calculateHlpTxsPercentage = (txs) => {
  const hlpAddresses = new Set([
    '0x010461c14e146ac35fe42271bdc1134ee31c703a',
    '0x2e3d94f0562703b25c83308a05046ddaf9a8dd14',
    '0x31ca8395cf837de08b24da3f660e77761dfb974b'
  ]);

  const hlpTxsCount = txs.filter(tx => hlpAddresses.has(tx.user)).length;
  return hlpTxsCount ? (hlpTxsCount / txs.length * 100).toFixed(2) : 0;
};


const Block = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [blockDetails, setBlockDetails] = useState(null);
    const [assetInfo, setAssetInfo] = useState(null);
    const [oracleUpdate, setOracleUpdate] = useState(null)
    const { blockNumber } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
          try {
            const exchangeMeta = await getExchangeMeta();
            const spotMeta = await getExchangeSpotMeta();
            let assetIdToDetails = formatExchangeMeta(exchangeMeta);
            assetIdToDetails = formatExchangeSpotMeta(spotMeta, assetIdToDetails);

            setAssetInfo(assetIdToDetails);

            const blockDetails = await getBlockDetails(blockNumber);
            setBlockDetails(blockDetails.blockDetails);
            if (blockDetails && blockDetails.blockDetails && blockDetails.blockDetails.txs) {
                const oracleUpdateTx = blockDetails.blockDetails.txs.find(
                  tx => tx.action.type === 'SetGlobalAction'
                )
                setOracleUpdate(oracleUpdateTx)
            }
            setLoading(false)
          } catch (error) {
            console.error('Error fetching data: ', error);
            setError(error)
          }
        };
        fetchData();
      }, [blockNumber]);

      const navigateToBlock = (blockNumber) => {
        if (blockNumber) {
            setError(null)
            setLoading(true)
            navigate('/block/' + blockNumber);;
        }
      };

      if (loading) {
        return (
          <div className='container'>
            <div className='inner-container'>Loading...</div>
          </div>
        );
      }

      if (error) {
        return (
          <div className='container'>
            <div className='inner-container'>Error: {error}</div>
          </div>
        );
      }

      let hlpTxsPercentage = '0.00';
      if (blockDetails && blockDetails.txs) {
          hlpTxsPercentage = calculateHlpTxsPercentage(blockDetails.txs);
      }
      
      return (
        <div className='container'>
            <div className='inner-container'>
            <h1>Block Details</h1>
            <div className='block-details'>              
              <div>
                <button className="block-nav" onClick={() => navigateToBlock(blockDetails.height - 1)} disabled={blockDetails.height <= 0}>
                &lt;
                </button>
                <span>Height: {blockDetails.height}</span>
                <button className="block-nav" onClick={() => navigateToBlock(blockDetails.height + 1)}>
                &gt;
                </button>
              </div>
              <p>Time: {new Date(blockDetails.blockTime).toISOString()}</p>
              <p>Transactions: {blockDetails.numTxs}</p>
              <p>Proposer: {blockDetails.proposer}</p>
              <p>Block Hash: {blockDetails.hash}</p>
              <p>HLP Txs: {hlpTxsPercentage}%</p>
            </div>
            <TxsTable data={blockDetails.txs} assetInfo={assetInfo}/>
            {assetInfo && blockDetails.txs && <AssetTable txs={blockDetails.txs} assetInfo={assetInfo}/> } 
            {!!oracleUpdate && ( 
              <div style={{marginTop: 40}}>
                <h3>Oracle Updates</h3>
                <OracleUpdateTable assetInfo={assetInfo} pxs={oracleUpdate.action.pxs}/>
              </div>
            )}
            </div>
        </div>
      );
}

export default Block