export default class HyperliquidWebSocketClient {
  constructor() {
    this.socket = new WebSocket('wss://api.hyperliquid.xyz/ws');
    this.subscriptions = {};
    this.queue = []; // Queue for messages waiting for the socket to open

    // Open connection and process any queued messages
    this.socket.onopen = () => {
      this.queue.forEach((message) => this.socket.send(message));
      this.queue = []; // Clear the queue once messages are sent
    };

    // Generalized message handler
    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      Object.values(this.subscriptions).forEach(callback => callback(message));
    };
  }

  subscribe(messageType, callback, additionalData = {}) {
    const message = JSON.stringify({
      method: 'subscribe',
      subscription: { type: messageType, ...additionalData }
    });

    // If socket is open, send immediately, else queue
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      this.queue.push(message);
    }

    // Store callback based on messageType and additionalData (e.g., coin) to differentiate multiple subscriptions of the same type
    const subscriptionKey = `${messageType}-${JSON.stringify(additionalData)}`;
    this.subscriptions[subscriptionKey] = callback;
  }

  unsubscribe(messageType, additionalData = {}) {
    const subscriptionKey = `${messageType}-${JSON.stringify(additionalData)}`;
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({
        method: 'unsubscribe',
        subscription: { type: messageType, ...additionalData }
      }));
    }

    delete this.subscriptions[subscriptionKey];
  }

  close() {
    this.socket.close();
  }
}
