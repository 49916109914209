
const formatExchangeMeta = (exchangeMetaResponse, map) => {
    let assetIdToDetails = map ?? new Map();
    if (!exchangeMetaResponse || !exchangeMetaResponse.universe) {
        console.error('Unexpected or invalid response')
    } else {
       for (let i = 0; i < exchangeMetaResponse.universe.length; i++) {
            // we assume no duplicates in API response.
            assetIdToDetails.set(i, exchangeMetaResponse.universe[i])
       }
    }
    return assetIdToDetails
}

const formatExchangeSpotMeta = (exchangeMetaResponse, map) => {
    let assetIdToDetails = map ?? new Map();
    if (!exchangeMetaResponse || !exchangeMetaResponse.universe) {
        console.error('Unexpected or invalid response')
    } else {
       for (let i = 0; i < exchangeMetaResponse.universe.length; i++) {
            // we assume no duplicates in API response.
            // spot meta have two fields in response: universe and tokens
            // the universe is an array of identifiers for pairs
            // tokens is a list of tokens w/ attributes
            // we will store just the pair for now by updating the name field
            // TODO: handle decimals accordingly from token
            const [tokenA, tokenB] = exchangeMetaResponse.universe[i].tokens.map(j => parseInt(j, 10));
            const marketName = `${exchangeMetaResponse.tokens[tokenA].name}/${exchangeMetaResponse.tokens[tokenB].name}`;
            // console.log(marketName)
            exchangeMetaResponse.universe[i].name = marketName
            assetIdToDetails.set(i + 10_000, exchangeMetaResponse.universe[i])
       }
    }
    return assetIdToDetails
}


const formatPrice = (price) => {
    if (!price) return '-';
  
    // Convert to a number if it's a string
    let num = typeof price === 'string' ? parseFloat(price) : price;
  
    // Count the number of digits before the decimal point
    let digitsBeforeDecimal = Math.floor(num).toString().length;
  
    // Calculate the number of decimal places to keep
    let decimalPlaces = Math.max(0, Math.min(6, 5 - digitsBeforeDecimal));
  
    // Format the number with the appropriate number of decimal places
    return num.toFixed(decimalPlaces);
  }
  

function formatSize(size, szDecimals) {
    if (!size) return '-';
    let num = typeof size === 'string' ? parseFloat(size) : size;
    return num.toFixed(szDecimals);
}
  

export { formatExchangeMeta, formatExchangeSpotMeta, formatPrice, formatSize }