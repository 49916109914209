import React from 'react';
import BookVisualization from './BookVisualization';

const BookGrid = ({ selectedBooks }) => {
  return (
    <div className='book-grid'>
      {selectedBooks.map(coin => (
        <BookVisualization key={coin} coin={coin} />
      ))}
    </div>
  );
};

export default BookGrid;
