import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Block from './pages/Block';
import Transaction from './pages/Transaction';
import BookVisualizationPage from './pages/Books';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/block/:blockNumber" element={<Block/>} />
        <Route path="/tx/:hash" element={<Transaction/>} />
        <Route path="/books" element={<BookVisualizationPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
