import React, { useState, useEffect } from 'react';
import { getExchangeMeta } from '../services/hyperliquidClient'
import BookGrid from '../components/Book/BookGrid';
import BookSelector from '../components/Book/BookSelector';

const BookVisualizationPage = () => {
  const [exchangeMeta, setExchangeMeta] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);

  useEffect(() => {
    getExchangeMeta().then(meta => setExchangeMeta(meta));
  }, []);

  const handleBookSelect = (coin) => {
    if (selectedBooks.includes(coin)) {
      setSelectedBooks(selectedBooks.filter(s => s !== coin));
    } else {
      if (selectedBooks.length < 8) {
        setSelectedBooks(prevBooks => [...prevBooks, coin]);
      } else {
        alert('You can only select up to 8 coins.');
      }
    }
  };
    

  if (!exchangeMeta || !exchangeMeta.universe) {
    return (
      <div className='container'>
        <div className='inner-container'>
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div className='container'>
        <div className='inner-container'>
            <h1>Book Visualization</h1>
            <BookSelector exchangeMeta={exchangeMeta.universe} onBookSelect={handleBookSelect} />
            <BookGrid selectedBooks={selectedBooks} />
        </div>
    </div>
  );
};

export default BookVisualizationPage;
