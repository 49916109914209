import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaRegClipboard, FaCheck } from 'react-icons/fa';

import { getTxDetails } from '../services/hyperliquidClient';

const copyToClipboard = text => {
  navigator.clipboard.writeText(text);
};

const Transaction = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [txDetails, setTxDetails] = useState(null);
    const [copiedHash, setCopiedHash] = useState(false);
    const [copiedBlock, setCopiedBlock] = useState(false);
    const [copiedUser, setCopiedUser] = useState(false);
    const { hash } = useParams();

    const handleCopy = (text, setter) => {
      copyToClipboard(text);
      setter(true);
      setTimeout(() => setter(false), 1000);
    };


    useEffect(() => {
        const fetchData = async () => {
          try {
            const txDetails = await getTxDetails(hash);
            setTxDetails(txDetails.tx);
            setLoading(false)
          } catch (error) {
            console.error('Error fetching data: ', error);
            setError(error)
          }
        };
        fetchData();
      }, [hash]);

      if (loading) {
        return (
          <div className='container'>
            <div className='inner-container'>Loading...</div>
          </div>
        );
      }

      if (error) {
        return (
          <div className='container'>
            <div className='inner-container'>Error: {error}</div>
          </div>
        );
      }
      
    return (
      <div className='container'>
        <div className='inner-container'>
          <h1>Tx Details: {txDetails.hash.slice(0, 6) + '...' + txDetails.hash.slice(-4)}</h1>
          <div>
            <p className="icon-align">Hash: {txDetails.hash} 
              {copiedHash ? 
                <FaCheck className="icon-spacing" title="Copied!" /> : 
                <FaRegClipboard className="icon-spacing" title="Copy hash" onClick={() => handleCopy(txDetails.hash, setCopiedHash)} />}
            </p>
            <p className="icon-align">Block: <Link to={`/block/${txDetails.block}`} className='base-link'>{ txDetails.block}</Link> 
              {copiedBlock ? 
                <FaCheck className="icon-spacing" title="Copied!" /> : 
                <FaRegClipboard className="icon-spacing" title="Copy block number" onClick={() => handleCopy(txDetails.block, setCopiedBlock)} />}
            </p>
            <p className="icon-align">Time: {new Date(txDetails.time).toISOString()}</p>
            <p className="icon-align">User: {txDetails.user} 
              {copiedUser ? 
                <FaCheck className="icon-spacing" title="Copied!" /> : 
                <FaRegClipboard className="icon-spacing" title="Copy user address" onClick={() => handleCopy(txDetails.user, setCopiedUser)} />}
            </p>
            <p className="icon-align">Action: {txDetails.action?.type}</p>
            <div>
              <pre style={{ lineHeight: '1.5', whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(txDetails.action || null, null, 2)}
              </pre>
            </div>
            {txDetails.error && <p className="icon-align bold red">Error: {txDetails.error}</p> }
          </div>
        </div>
      </div>
    );

}

export default Transaction