import React, { useState, useEffect } from 'react';
import HyperliquidWebSocketClient from '../../clients/HyperliquidWebSocketClient';
import BookDataVisualization from './BookDataVisualization';
import RecentTrades from './RecentTrades';

const BookVisualization = ({ coin }) => {
  const [bookData, setBookData] = useState(null);
  const [trades, setTrades] = useState(null)

  useEffect(() => {
    const client = new HyperliquidWebSocketClient();
  
    client.subscribe('trades', ({data}) => {
      console.log('1')
      console.log(data)
      if (data) {
        setTrades(currentTrades => {
          // Prepend the new trade and limit the array to the 100 most recent items
          const updatedTrades = [data, ...(currentTrades ?? [])].slice(0, 100);
          return updatedTrades; // No need to reverse
        });
      }
    }, { coin: coin });
  
    // client.subscribe('l2Book', ({data}) => {
    //   if (data.coin === coin) {
    //     setBookData(data);
    //   }
    // }, { coin: coin });
  
    return () => {
      client.unsubscribe('l2Book', { coin: coin });
      client.unsubscribe('trades', { coin: coin })
      client.close();
    };
  }, [coin]);

  if (!bookData) {
    return <div>Loading book...</div>;
  }

  const bids = bookData?.levels[0]
  const asks = bookData?.levels[1]

  return (
    <div>
      <h3>{coin}</h3>
      <BookDataVisualization coin={coin} bids={bids} asks={asks} />
      {/* <RecentTrades trades={trades}/> */}
    </div>
  );
};

export default BookVisualization;
