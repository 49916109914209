import React, { useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';

function formatNum(amount) {
    // Round to two decimal places
    if (amount === 0) {
      return '0.00'
    }
    if (!amount) {
        return ''
    }
    let roundedAmount = Math.round(amount * 100) / 100;
  
    // Convert to string and add commas as thousands separators
    return roundedAmount.toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
  }

function AssetTable({ txs, assetInfo }) {
  const data = useMemo(() => {
    const stats = {};
    let totalUpdates = 0;
    let totalCancels = 0;
    let totalALO = 0;

    txs.forEach(tx => {
     let asset;
     if (tx.action.type === 'cancel' && tx.action.cancels.length) {
        asset = assetInfo.get(tx.action.cancels[0].a).name
     }
     if (tx.action.type === 'cancelByCloid' && tx.action.cancels.length) {
        asset = assetInfo.get(tx.action.cancels[0].asset).name
     }
     if (tx.action.type === 'order' && tx.action.orders.length) {
        asset = assetInfo.get(tx.action.orders[0].a).name
     }

     if (!asset){
        return;
     }

      if (!stats[asset]) {
        stats[asset] = {
          numUpdates: 0,
          numLimitOrders: 0,
          numCancels: 0,
          numMarketTrades: 0,
          orderSizes: [],
          numBuyLimitOrders: 0,
          numSellLimitOrders: 0,
        };
      }

      stats[asset].numUpdates++;
      totalUpdates++;


      if (tx.action.type === 'order') {
          stats[asset].orderSizes.push(parseFloat(tx.action.orders[0].s) * 100.00 / 100.00);
          if (tx.action?.orders[0]?.t.limit.tif === 'Alo') {
            totalALO++;
          }
          if (tx.action?.orders[0]?.t?.limit?.tif === 'FrontendMarket') {
            stats[asset].numMarketTrades++;
            } else {
            stats[asset].numLimitOrders++;
          }
          if (tx.action.orders[0].b) {
            stats[asset].numBuyLimitOrders++;
          } else {
            stats[asset].numSellLimitOrders++;
          }
      } else if (tx.action.type.includes('cancel')) {
        stats[asset].numCancels++;
        totalCancels++;
      }
    });

    // Calculate order size statistics
    for (const asset in stats) {
      const sizes = stats[asset].orderSizes;
      stats[asset].avgOrderSize = sizes.length > 0 ? sizes.reduce((a, b) => a + b, 0) / sizes.length : '';
      stats[asset].maxOrderSize = sizes.length > 0 ? Math.max(...sizes) : '';
      stats[asset].minOrderSize = sizes.length > 0 ? Math.min(...sizes) : '';
      if (stats[asset].numSellLimitOrders === 0 && stats[asset].numBuyLimitOrders === 0) {
        stats[asset].buySellRatio = ''
      } else {
        stats[asset].buySellRatio = stats[asset].numSellLimitOrders ? stats[asset].numBuyLimitOrders / stats[asset].numSellLimitOrders : stats[asset].numBuyLimitOrders / 1
      }
    }

    const cancelPercentage = totalALO > 0 ? (totalALO / totalUpdates) * 100 : 0;
    const aloPercentage = totalUpdates > 0 ? (totalCancels / totalUpdates) * 100 : 0;

    return {
      stats: Object.entries(stats).map(([asset, stat]) => ({
        asset,
        ...stat
    })),
    cancelPercentage,
    aloPercentage
    }
  }, [txs, assetInfo]);

  const columns = useMemo(
    () => [
      {
        Header: 'Asset',
        accessor: 'asset',
      },
      {
        Header: 'Number of Updates',
        accessor: 'numUpdates',
      },
      {
        Header: 'Number of Limit Orders',
        accessor: 'numLimitOrders',
      },
      {
        Header: 'Number of Cancels',
        accessor: 'numCancels',
      },
      {
        Header: 'Number of Market Trades',
        accessor: 'numMarketTrades',
      },
      {
        Header: 'Average Order Size',
        accessor: 'avgOrderSize',
        Cell: ({ value }) => formatNum(value)
      },
      {
        Header: 'Max Order Size',
        accessor: 'maxOrderSize',
        Cell: ({ value }) => formatNum(value)
      },
      {
        Header: 'Min Order Size',
        accessor: 'minOrderSize',
        Cell: ({ value }) => formatNum(value)
      },
      {
        Header: 'Buy/Sell Ratio',
        accessor: 'buySellRatio',
        Cell: ({ value }) => formatNum(value)
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data.stats,
      initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'numUpdates', desc: true }] },
    },
    useSortBy,
    usePagination
  );

  return (
    <div style={{marginTop: 40}}>
      <h3>Summary Stats</h3>
      <p>Cancels as a Pct of all Updates: {data.cancelPercentage.toFixed(2)}%</p>
      <p>ALOs as a Pct of all Updates: {data.aloPercentage.toFixed(2)}%</p>
      <table {...getTableProps()} style={{ width: '100%', margin: '0 auto' }} className='asset-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td style={{'textAlign': 'center'}} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default AssetTable;