import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';

import { formatPrice, formatSize } from '../../services/formatter'
import { getExchangeMeta } from '../../services/hyperliquidClient'

// TODO refactor this out for assetMeta globally
// TODO: add level size support via UI and URL
const BookDataVisualization = ({ bids, asks, levels=20, levelSize, coin }) => {

  const [szDecimals, setSzDecimals] = useState(null)

  useEffect(() => {
    getExchangeMeta().then(meta => {
      const decimals = meta.universe.find(a => a.name === coin)?.szDecimals
      if (decimals) {
        setSzDecimals(decimals)
      }
    });
  }, [coin]);

  // TODO: consider whether this should be based on all levels or just those in view
  const maxQuantity = useMemo(() => {
    const allQuantities = [...bids.slice(0, levels/2), ...asks.slice(0, levels/2)].map(item => item.sz || 0);
    return Math.max(...allQuantities);
  }, [bids, asks, levels]);


  // TODO: consider num people in queue at level
  const asksData = useMemo(() => {
    let asksDataFormatted = []
    for (let i = Math.floor(levels/2); i >= 0 ; i--) {
      const price = formatPrice(asks[i].px) || ''
      const amount = formatSize(parseFloat(asks[i].sz), szDecimals)|| ''
      const total = formatPrice(price * amount) || ''
      asksDataFormatted.push({
        price,
        amount,
        total
      });
    }
    return asksDataFormatted;
  }, [asks, levels, szDecimals]);

  const bidsData = useMemo(() => {
    let bidsDataFormatted = []
    for (let i = 0; i < Math.floor(levels/2); i++) {
      const price = formatPrice(bids[i].px) || ''
      const amount = formatSize(parseFloat(bids[i].sz), szDecimals) || ''
      const total = formatPrice(price * amount) || ''
      bidsDataFormatted.push({
        price,
        amount,
        total
      });
    }
    return bidsDataFormatted;
  }, [bids, levels, szDecimals]);

  const midData = useMemo(() => {
    const spreadAbsolute = formatPrice(parseFloat(asks[0].px) - parseFloat(bids[0].px))
    const spreadAsBps = (spreadAbsolute / parseFloat(asks[0].px) * 1000).toFixed(2) + '%'
    return [{
      price: '',
      amount: spreadAbsolute,
      total: spreadAsBps,
    }]
  }, [bids, asks])

  const columns = useMemo(
    () => [
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Amount',
        accessor: 'amount'
      },
      {
        Header: 'Total',
        accessor: 'total',
      }
    ],
    []
  );

  const getRowStyle = ({ original }) => {
    const highestBid = parseFloat(bids[0].px)
    const lowestAsk = parseFloat(asks[0].px)
    const midPrice = formatPrice((highestBid + lowestAsk) / 2.0)
    const isAsk = original.price > midPrice;
    const isBid = original.price < midPrice;
    // TODO: add mid/spread styling
    if (isBid || isAsk) {
      const basePercent = original.amount / maxQuantity;
      const exponent = 1.5;
      const widthPercent = Math.pow(basePercent, exponent) * 100;
      const minWidthPercent = 5;
      const adjustedWidthPercent = Math.max(widthPercent, minWidthPercent);
  
      const backgroundColor = isAsk ? `rgba(250, 128, 114, ${adjustedWidthPercent / 100})` : `rgba(144, 238, 144, ${adjustedWidthPercent / 100})`;
      const backgroundStyle = `linear-gradient(to right, ${backgroundColor} ${adjustedWidthPercent}%, transparent ${adjustedWidthPercent}%)`;
  
      return { background: backgroundStyle };
    }
  };

  const formattedData = [...asksData, ...midData, ...bidsData].flat()
  const tableInstance = useTable({ columns, data: formattedData });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()} className='l2book-top-level'>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className='l2book'>
        {rows.map(row => {
          prepareRow(row);
          const rowStyle = getRowStyle(row);
          return (
            <tr {...row.getRowProps()} style={rowStyle}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default BookDataVisualization;
