import axios from 'axios';

const EXPLORER_API_URL = 'https://api.hyperliquid.xyz/explorer';
const INFO_API_URL = 'https://api.hyperliquid.xyz/info'

const getBlockDetails = async (blockNumber) => {
  if (typeof blockNumber === 'string') {
    blockNumber = parseInt(blockNumber)
  }
  const response = await axios.post(EXPLORER_API_URL, {
    height: blockNumber,
    type: 'blockDetails'
  });
  return response.data;
};

const getExchangeMeta = async () => {
  const response = await axios.post(INFO_API_URL, {
    type: 'meta'
  });

  return response.data
}

const getExchangeSpotMeta = async () => {
  const response = await axios.post(INFO_API_URL, {
    type: 'spotMeta'
  });

  return response.data
}

const getTxDetails = async (hash) => {
  const response = await axios.post(EXPLORER_API_URL, {
    hash,
    type: 'txDetails'
  });
  return response.data;
}


export { getBlockDetails, getExchangeMeta, getExchangeSpotMeta, getTxDetails };
